import React from "react"
import GatsbyImageSharpFluid from "../components/gatsbyImageSharpFluid.js"
import "./realEstateHero.scss"
const RealEstateHero = () => {
  return (
    <div className="realestate-hero">
      <div />
      <div className="realestate-hero__box">
        <GatsbyImageSharpFluid
          imgsrc={"house.jpg"}
          className="realestate-hero__photo"
        />

        <div className="realestate-hero__text">
          <h3>Real Estate</h3>
          <h4>Toronto Canada</h4>
        </div>
      </div>
      <div />
    </div>
  )
}

export default RealEstateHero
