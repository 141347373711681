import React from "react"
import { Link } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import "./realEstateMap.scss"

const RealEstateMap = () => {
  const regionsList = [
    {
      name: "halton",
      d:
        "M23,892l79-57,11-3,121-83L336,943l-13,11-1,6,2,3,29,56v17l8,13,19,31h4l17-7,27,46-79,60-7,9,1,16-21,4-9,4-44,37-25,13-33,12-49,13-20-37,55-46,1-4Z",
      textX: "113.155",
      textY: "984.286",
    },
    {
      name: "peel",
      d:
        "M487,524l9,80,3,49,5,53v12l2,47,1,24,1,22,1,13,1,19,1,10,1,32,1,18,1,17,1,14-14,7-3,3v2l19,12v21l7,12-4,5,1,7v8l13,10-16,17-22,1-7,6-4,5-2,6-4,4-8,7-7,6-6,10-2,8-1,6-1,9-17,9-29-49-2-3h-5l-15,5-23-33-1-21-27-46-3-10,3-3,12-9v-3l-2-4-45-90-5-12L154,568l35-20,2-5-8-12,13-6,2-21,29,6v13l2,3Z",
      textX: "349.115",
      textY: "806.424",
    },
    {
      name: "york",
      d:
        "M499,526l19,295H920l2-440h144V285l-67,1-17-4-12-4H960l-18,3-11,5-4,2-14-20H828l-33,30,6,9,3,4v5l-4,6-3,2,11,14,1,5-2,5-29,33-37,46-14,13-35,46-87,38Z",
      textX: "664.186",
      textY: "689.511",
    },
    {
      name: "toronto",
      d:
        "M519,831l401,1,1,41,29,15L786,983l-18,1-9,2-6,5-5,5-3,3-14,5-17,5-8,3-8,2-6,1-5,1-5-1-4-3-2-5-2-4v-5l-3-4-5-1-9,1-5,1-4-4-5-6-8-7-5-2H619l-7,2-5,3-35,31-5,1-24,2-13-11v-6l3-7-2-7-2-4-3-7V953l-12-9,11-8Z",
      textX: "623.362",
      textY: "919.62",
    },
    {
      name: "durham",
      d:
        "M1538,709V895l-45-10-18-6-24-10h-46l-12,3-11,10-7-4h-9l-11,8-87-30-9-2-5,3-7,8-6,2h-28l-15-12-5-1-12,8-19-21-10,1-9,9h-18l-17,13-15-5-13,5-11,5-21-7-9,3-8,5-17-20-12,3-42,29-30-13,2-478h144l-2-112,71-150,32-16,48,4-3,361,1,40,1,20,1,11,2,7,3,2,3,1,12-1,65-57,5,6v12l-3,15-4,17-2,10-1,5,2,5,5,3,20-1,51-42,9,14,1,13-3,148,3,4Z",
      textX: "1055.535",
      textY: "689.926",
    },
  ]
  return (
    <section className="listings">
      <svg
        className="listings__map"
        viewBox="0 0 1572 1314"
        xmlns="http://www.w3.org/2000/svg"
      >
        {regionsList.map(region => {
          return (
            <Link to={`/realEstate/${region.name}`} key={uuidv4()}>
              <path
                className={`gta-map gta-map--${region.name}`}
                d={region.d}
              ></path>
            </Link>
          )
        })}

        <g>
          {regionsList.map(region => {
            return (
              <Link to={`/realEstate/${region.name}`} key={uuidv4()}>
                <text
                  data-name={region.name}
                  className={`cityname cityname--${region.name}`}
                  x={region.textX}
                  y={region.textY}
                >
                  <tspan x={region.textX}>{region.name}</tspan>
                </text>
              </Link>
            )
          })}
        </g>
      </svg>
    </section>
  )
}

export default RealEstateMap
