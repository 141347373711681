import React from "react"
import Layout from "../components/layout.js"
import "./realestate.scss"
import Head from "../components/head.js"
import BackgroundSection from "../components/backgroundSection.js"
import RealEstateHero from "../components/realEstateHero.js"
import RealEstateMap from "../components/realEstateMap.js"

const RealEstate = () => {
  return (
    <>
      <div className="realestate-pg">
        <Head title={"Real Estate"} />
        <BackgroundSection imgsrc={"realestatebg.jpg"}>
          <Layout page={"realestate"}>
            <RealEstateHero />
            <aside className="info">
              <div className="info__border">
                <div>
                  <h2 className="info__title">
                    Buying a Home or Selling your Home?
                  </h2>
                  <p className="info__details">
                    With my years of experience with managing construction sites
                    for companies that build homes, I know the value of a house
                    and can help you get the results that best suits your needs.
                    Select the region your interested in to see my listings
                    whether, on sale, bought or sold.
                  </p>
                </div>

                {/* <div className="info__border">
                  <div>
                    <h2 className="info__title">Buying a Home</h2>
                    <p className="info__details">This process can be </p>
                  </div>
                </div>
                <div className="info__border">
                  <div>
                    <h2 className="info__title">Selling your Home</h2>
                    <p className="info__details"></p>
                  </div>
                </div> */}
              </div>
            </aside>
            <RealEstateMap />
          </Layout>
        </BackgroundSection>
      </div>
    </>
  )
}

export default RealEstate
